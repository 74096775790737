<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title :  '新增' + title"
         width="600"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <div class="section-title">
        <span>基础信息</span>
      </div>
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>设施类型：</p>
          <Cascader :data="typeArr"
                    v-model="type"
                    style="width:100%;"
                    @on-change="onChangeCascader"></Cascader>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>设施名称：</p>
          <Input v-model="name"
                 placeholder="设施名称"
                 maxlength="30"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">设施编号：</p>
          <Input v-model="number"
                 placeholder="设施编号"
                 maxlength="10"
                 clearable></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>所属机构：</p>
          <MyTreeSelect :value="departName"
                        placeholder="所属机构"
                        :data="departmentTreeArr"
                        search
                        @onSelectChange="onChangeDepartment">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">责任人：</p>
          <MyTreeSelect :value="principal"
                        placeholder="责任人"
                        clearable
                        :data="principalTreeArr"
                        search
                        @onSelectChange="onChangePrincipal">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">设施状态：</p>
          <Select v-model="status"
                  placeholder="设施状态"
                  clearable>
            <Option :value="item.id"
                    v-for="item in statusArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">维护单位：</p>
          <MyTreeSelect :value="maintain"
                        placeholder="维护单位"
                        clearable
                        :data="maintainTreeArr"
                        search
                        @onSelectChange="onChangeMaintain">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>点位半径：</p>
          <Input v-model="radius"
                 placeholder="点位半径"
                 maxlength="30"
                 v-stringLimit
                 class="input-right-5"></Input>
          <span class="li-unit">米</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">启用日期：</p>
          <DatePicker :value="date"
                      type="date"
                      placeholder="启用日期"
                      @on-change="onChangeDate"></DatePicker>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">商圈：</p>
          <Select v-model="business"
                  placeholder="商圈"
                  clearable>
            <Option :value="item.id"
                    v-for="item in businessArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>地址：</p>
          <Input v-model="site"
                 readonly
                 placeholder="地址">
          <Icon custom="i-icon icon-weizhishi"
                size="20"
                color="#25bb96"
                class="cursor-pointer"
                slot="suffix"
                @click.stop="onClickSite" />
          </Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
        <li class="form-ul-li">
          <p class="li-title">文件多媒体：</p>
          <ul class="file-img">
            <UploadFile @uploadSuccess="uploadPicture"
                        :disabled="picture">
              <li>
                <el-image :src="picture"
                          fit="cover"
                          :preview-src-list="[picture]"
                          class="w-full h-full"
                          v-show="picture">
                </el-image>
                <Icon type="md-close-circle"
                      size="24"
                      color="#fa5357"
                      class="file-img-deleteIcon"
                      @click.stop="onClickDeletePicture"
                      v-show="picture" />
                <Icon custom="i-icon icon-tupianshangchuan"
                      size="40"
                      color="#B5B5B5"
                      v-show="!picture" />
              </li>
            </UploadFile>
          </ul>
        </li>
        <li class="form-ul-li">
          <p class="li-title"></p>
          <div class="file-tip">
            图片：支持格式为png、jpg、jpeg、bmp，最多不能超过5张，最大不能超过1M；
          </div>
        </li>
      </ul>
      <template v-if="manholeCoverVisible">
        <div class="section-title">
          <span>自检设置</span>
        </div>
        <ul class="form-ul">
          <li class="form-ul-li a-center">
            <p class="li-title"><span class="li-must">*</span>自检周期：</p>
            <span class="m-r-10">每</span>
            <Select v-model="selfInspection"
                    clearable
                    class="m-r-10">
              <Option :value="item.id"
                      v-for="item in selfInspectionArr"
                      :key="item.id">{{item.name}}
              </Option>
            </Select>
            <Input v-model="selfInspectionCount"
                   class="m-r-10"></Input>
            <span>次</span>
          </li>
          <li class="form-ul-li">
            <p class="li-title">提醒周期：</p>
            <ul>
              <li v-for="(item,index) in remindArr"
                  :key="index"
                  class="m-b-10 remind">
                <RadioGroup v-model="item.remindRadio"
                            vertical>
                  <div class="flex a-center">
                    <Radio :label="0">
                      每周
                    </Radio>
                    <Select v-model="item.week"
                            clearable
                            class="m-r-10"
                            style="width:180px">
                      <Option :value="item.id"
                              v-for="item in weekArr"
                              :key="item.id">{{item.name}}
                      </Option>
                    </Select>
                    <TimePicker :value="item.weekTime"
                                format="HH:mm"
                                style="width:180px"
                                @on-change="value => onChangeWeekTime(value,item)"></TimePicker>
                  </div>
                  <div class="flex a-center">
                    <Radio :label="1"
                           class="m-t-10">
                      每月
                    </Radio>
                    <Select v-model="item.month"
                            clearable
                            class="m-r-10"
                            style="width:180px">
                      <Option :value="item.id"
                              v-for="item in monthArr"
                              :key="item.id">{{item.name}}
                      </Option>
                    </Select>
                    <TimePicker :value="item.monthTime"
                                format="HH:mm"
                                style="width:180px"
                                @on-change="value => onChangeMonthTime(value,item)"></TimePicker>
                  </div>
                  <div class="flex a-center">
                    <Radio :label="2"
                           class="m-t-10">
                      每年
                    </Radio>
                    <DatePicker type="date"
                                :value="item.yearDate"
                                class="m-r-10"
                                style="width:180px"
                                @on-change="value => onChangeYearDate(value,item)"></DatePicker>
                    <TimePicker :value="item.yearTime"
                                style="width:180px"
                                @on-change="value => onChangeYearTime(value,item)"></TimePicker>
                  </div>
                </RadioGroup>
                <Icon custom="i-icon icon-shanchu"
                      size="18"
                      class="remind-icon cursor-pointer"
                      color="#2bb99b"
                      @click.stop="onClickDeleteRemind(index)" />
                <div class="m-t-10">
                  <Icon custom="i-icon icon-tishi1"
                        size="16"
                        color="#FF9B77"></Icon>
                  注：特殊月份无29、30、31日的，选择此日期的特殊月份将在本月最后一天提醒。
                </div>
              </li>
              <li>
                <Button type="text"
                        @click.stop="onClickAddRemind">
                  <Icon type="ios-add-circle-outline"
                        size="16" />
                  添加提醒日期
                </Button>
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
    <PositionModal v-model="positionVisible"
                   :lng="lng"
                   :lat="lat"
                   @onClickConfirm="onChangeSite" />
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MyTreeSelect from '@/components/common/MyTreeSelect'
import UploadFile from '@/components/common/UploadFile'
import PositionModal from '@/components/product/main/template/PositionModal'
export default {
  components: {
    MyTreeSelect,
    UploadFile,
    PositionModal
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '设施'
    },
    dataId: String
  },
  data () {
    return {
      loading: false,
      type: [], // 设施类型
      typeName: [], // 设施类型名称
      typeArr: [],
      name: '', // 设施名称
      number: '', // 设施编号
      departId: '', // 所属机构id
      departName: '', // 所属机构
      principalId: '', // 责任人id
      principal: '', // 责任人
      status: 0, // 设施状态
      statusArr: [
        {
          id: 0,
          name: '正常',
        }, {
          id: 1,
          name: '废弃',
        }, {
          id: 2,
          name: '维修',
        }, {
          id: 3,
          name: '拆除',
        }, {
          id: 4,
          name: '暂停',
        },
      ],
      maintainId: '', // 维护单位id
      maintain: '', // 维护单位
      radius: '', // 点位半径
      date: '', // 启用日期
      business: '', // 商圈
      remark: '', // 备注
      pullIn: '', // 进站设备号
      outbound: '', // 出站设备号
      area: '', // 面积
      picture: '', // 图片
      businessArr: [],
      manholeCoverVisible: false,
      // 选择地址
      positionVisible: false,
      lng: '',
      lat: '',
      site: '',
      // 窨井盖
      selfInspection: null,
      selfInspectionArr: [
        {
          id: 0,
          name: '周'
        }, {
          id: 1,
          name: '月'
        }, {
          id: 2,
          name: '年'
        },
      ],
      selfInspectionCount: '',
      remindArr: [
        {
          remindRadio: null,
          week: '',
          weekTime: '',
          month: '',
          monthTime: '',
          yearDate: '',
          yearTime: '',
        }
      ]
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    },
    visible (newVal) {
      newVal && this.getFacilityBigTypeAllList()
    }
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
      'maintainTreeArr': 'getDepartmentTreeList',
      'principalTreeArr': 'getUserTreeList',
      'weekArr': 'getWeekArr',
      'monthArr': 'getMonthArr',
    }),
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateDepartmentTreeList',
      'updateUserTreeList',
    ]),
    init () {
      this.updateDepartmentTreeList()
      this.updateUserTreeList()
    },
    // 获取详情
    getDetail () {
      this.$store.dispatch('getFacilityDetail', this.dataId).then(res => {
        console.log(res);
        for (const i in res) {
          this[i] = res[i]
        }
        this.type = [res.bigType, res.smallType]
        if (res.lnglat) {
          let lnglatArr = res.lnglat.split(',')
          this.lng = lnglatArr[0]
          this.lat = lnglatArr[1]
        }
        this.status = parseInt(res.status)
        if (res.bigTypeName == '窨井盖') {
          this.manholeCoverVisible = true
          this.selfInspection = parseInt(res.others[0].selfInspection)
          this.selfInspectionCount = res.others[0].selfInspectionCount
          this.remindArr = res.others[0].details.map(item => {
            let obj = {
              remindRadio: parseInt(item.remindRadio),
              week: '',
              weekTime: '',
              month: '',
              monthTime: '',
              yearDate: '',
              yearTime: '',
            }
            let time = item.remindtime.split(' ')
            if (item.remindRadio == 0) {
              obj.week = parseInt(item.remindday)
              obj.weekTime = time[1]
            } else if (item.remindRadio == 1) {
              obj.month = parseInt(item.remindday)
              obj.monthTime = time[1]
            } else {
              obj.yearDate = time[0]
              obj.yearTime = time[1]
            }
            return obj
          })
        }
      })
    },
    // 获取设施类型
    getFacilityBigTypeAllList () {
      this.$http.getFacilityBigTypeAllList({
        type: 'facl'
      }).then(res => {
        if (res.code === 200) {
          this.typeArr = res.result.map(item => {
            return {
              value: item.id,
              label: item.name,
              children: item.items.map(items => {
                return {
                  value: items.id,
                  label: items.name
                }
              })
            }
          })
        }
      })
    },
    // 级联回调
    onChangeCascader (value, selectedData) {
      this.typeName = selectedData.map(item => {
        return item.label
      })
      if (this.typeName[0] == '窨井盖') {
        this.manholeCoverVisible = true
      }
    },
    // 修改机构
    onChangeDepartment (data) {
      this.departId = data.id
      this.departName = data.name
    },
    // 修改责任人
    onChangePrincipal (data) {
      if (data.type !== 'depart') {
        this.principalId = data.id
        this.principal = data.name
      }
    },
    // 修改维护单位
    onChangeMaintain (data) {
      this.maintainId = data.id
      this.maintain = data.name
    },
    // 修改日期
    onChangeDate (date) {
      this.date = date
    },
    // 点击选择地址
    onClickSite () {
      this.positionVisible = true
    },
    // 修改地址
    onChangeSite (e) {
      this.site = e.address
      this.lng = e.lng
      this.lat = e.lat
    },
    // 上传照片
    uploadPicture (arr) {
      if (arr.length > 0) {
        this.picture = arr[0].url
      }
    },
    // 删除图片
    onClickDeletePicture () {
      this.picture = ''
    },
    // 窨井盖 start
    onChangeWeekTime (value, item) {
      item.weekTime = value
      console.log(item);
    },
    onChangeMonthTime (value, item) {
      item.monthTime = value
    },
    onChangeYearDate (value, item) {
      item.yearDate = value
    },
    onChangeYearTime (value, item) {
      item.yearTime = value
    },
    // 窨井盖增加提醒周期
    onClickAddRemind () {
      this.remindArr.push({
        week: '',
        weekTime: '',
        month: '',
        monthTime: '',
        yearDate: '',
        yearTime: '',
      })
    },
    // 窨井盖删除提醒周期
    onClickDeleteRemind (index) {
      this.remindArr.splice(index, 1)
    },
    // 点击确认
    onClickConfirm () {
      if (!this.type) return this.$Message.info('请选择设施类型');
      if (!this.name) return this.$Message.info('请输入设施名称');
      if (!this.departId) return this.$Message.info('请选择所属机构');
      if (!this.radius) return this.$Message.info('请输入点位半径');
      if (!this.site) return this.$Message.info('请选择地址');
      // 查找大类 小类的名称  为修改20210908之前的数据使用，之后新增数据在级联回调就会赋值
      let type = this.typeArr.find(item => {
        return item.value == this.type[0]
      })
      let smallType = type.children.find(item => {
        return item.value == this.type[1]
      })
      let typeName = []
      if (this.typeName.length == 0) {
        typeName = [type.label, smallType.label]
      } else {
        typeName = this.typeName
      }
      let params = {
        name: this.name,
        bigType: this.type[0],
        bigTypeName: typeName[0],
        smallType: this.type[1],
        smallTypeName: typeName[1],
        number: this.number,
        departId: this.departId,
        departName: this.departName,
        principalId: this.principalId,
        status: this.status,
        maintainId: this.maintainId,
        radius: this.radius,
        date: this.date,
        business: this.business,
        lnglat: this.lng + ',' + this.lat,
        site: this.site,
        remark: this.remark,
        picture: this.picture,
        others: []
      }
      if (typeName[0] == '窨井盖') {
        if (![0, 1, 2].includes(this.selfInspection)) return this.$Message.info('请选择自检周期');
        if (!this.selfInspectionCount) return this.$Message.info('请输入自检周期次数');
        params.selfInspection = this.selfInspection // 自检周期
        params.selfInspectionCount = this.selfInspectionCount // 周期内自检次数
        params.others = this.remindArr.map(item => {
          let obj = {
            selfInspection: this.selfInspection, // 自检周期
            selfInspectionCount: this.selfInspectionCount, // 周期内自检次数
            remindRadio: item.remindRadio, // 提醒类型
          }
          if (item.remindRadio == 0) {
            obj.remindday = item.week     // 提醒日
            obj.remindtimeWeekOrMonth = item.weekTime
          } else if (item.remindRadio == 1) {
            obj.remindday = item.month
            obj.remindtimeWeekOrMonth = item.monthTime
          } else if (item.remindRadio == 2) {
            obj.remindtime = item.yearDate + ' ' + item.yearTime
          }
          return obj
        })
      }
      if (this.dataId) params.ids = this.dataId
      this.loading = true
      this.$store.dispatch('createOrEditFacility', params).then(res => {
        this.loading = false
        this.$emit('onClickConfirm')
        this.$emit('onChange', false)
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {
      this.type = []
      this.typeName = []
      this.name = ''
      this.number = ''
      this.departId = ''
      this.departName = ''
      this.principalId = ''
      this.principal = ''
      this.status = 0
      this.maintainId = ''
      this.maintain = ''
      this.radius = ''
      this.date = ''
      this.business = ''
      this.lng = ''
      this.lat = ''
      this.site = ''
      this.remark = ''
      this.site = ''
      this.picture = ''
      // this.pullIn = ''
      // this.outbound = ''
      // 窨井盖
      this.manholeCoverVisible = false
      this.selfInspection = null
      this.selfInspectionCount = ''
      this.remindRadio = null
      this.remindArr = [
        {
          week: '',
          weekTime: '',
          month: '',
          monthTime: '',
          yearDate: '',
          yearTime: '',
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;

  span:first-child {
    font-weight: 700;
    font-size: 16px;
    padding-left: 10px;
    border-left: 2px solid #25bb96;
  }
}
.remind {
  position: relative;
  .remind-icon {
    position: absolute;
    top: 0;
    right: -5px;
  }
}
</style>
