<template>
  <Drawer :title="title"
          closable
          width="45"
          :value="visible"
          @on-visible-change="onChangeVisible">
    <div class="detail"
         v-if="data">
      <div class="detail-top">
        <div class="detail-top-title">
          <img :src="data.msg.headImg"
               alt=""
               v-if="data.msg && data.msg.headImg">
          <Icon v-else
                custom="i-icon icon-lajishoujidian"
                size="32"
                color="#ffffff"
                class="detail-top-title-icon" />
          <div class="detail-top-title-div">
            <p>{{data.name || "--"}}</p>
            <div><span>所属机构：</span>{{data.departName || "--"}}</div>
          </div>
        </div>
        <div class="detail-top-msg">
          <p>责任人：<span>{{data.principal || "--"}}</span></p>
          <p>点位半径：<span>{{data.radius || "--"}}</span></p>
          <p>维护单位：<span>{{data.maintain || "--"}}</span></p>
        </div>
        <div>
          <el-image :src="data.picture"
                    fit="cover"
                    :preview-src-list="[data.picture]"
                    class="detail-top-img"
                    v-if="data.picture">
          </el-image>
          <span v-else>暂无多媒体文件</span>
        </div>
        <p>
          <Icon custom="i-icon icon-dingwei"
                size="16"
                color="#000" />
          <span>{{data.site}}</span>
        </p>
      </div>
      <Tabs v-model="detailTabIndex">
        <TabPane label="基础概况"></TabPane>
        <!-- <TabPane label="拓展信息"></TabPane> -->
      </Tabs>
      <div v-show="detailTabIndex == 0">
        <div class="section">
          <div class="section-title">
            <span>基础信息</span>
          </div>
          <ul class="section-cont">
            <li>
              <label>启用日期:</label>
              <span>{{data.date || "--"}}</span>
            </li>
            <li>
              <label>状态:</label>
              <span>{{data.status || "--"}}</span>
            </li>
            <li>
              <label>设施编号:</label>
              <span>{{data.number || "--"}}</span>
            </li>
            <li class="one-row">
              <label>备注:</label>
              <span>{{data.remark || "--"}}</span>
            </li>
          </ul>
        </div>
        <div class="section"
             v-if="data.bigTypeName == '公厕' && visitorsFlowrate">
          <div class="section-title">
            <span>公厕人流量统计</span>
          </div>
          <ul class="section-cont">
            <li>
              <label>今日人流量:</label>
              <span>{{visitorsFlowrate.intoday || "--"}}</span>
            </li>
            <li>
              <label>总人流量:</label>
              <span>{{visitorsFlowrate.intotal || "--"}}</span>
            </li>
          </ul>
        </div>
        <div class="section">
          <div class="section-title">
            <span>绑定设备</span>
          </div>
          <div>
            <Table ref="equipmentTable"
                   :columns="columnsEquipmentList"
                   :data="tableEquipmentData"
                   border
                   stripe
                   :loading="tableEquipmentLoading">
            </Table>
            <!-- <div class="noData">
              <img src="../../../../../assets/common/biaogeno.png">
              <p>暂无数据</p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '设施档案'
    },
    dataId: String
  },
  data () {
    return {
      data: {},
      visitorsFlowrate: {},   // 人流量
      detailTabIndex: 0,
      columnsEquipmentList: [
        {
          title: '设备编号',
          key: 'equipcode',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '设备类型',
          key: 'equiptypeid',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '设备通讯号',
          key: 'communicationcode',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '厂商',
          key: 'manufacturerid',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        }
      ],
      tableEquipmentData: [],
      tableEquipmentLoading: false,
      totalPage: 0,
      pageNum: 1,
      pageSize: 99999,
    };
  },
  watch: {
    dataId (newValue) {
      if (newValue) {
        this.getDetail()
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    getDetail () {
      this.$store.dispatch('getFacilityDetail', this.dataId).then(res => {
        this.data = res
        switch (this.data.status) {
          case '0':
            this.data.status = '正常'
            break;
          case '1':
            this.data.status = '废弃'
            break;
          case '2':
            this.data.status = '维修'
            break;
          case '3':
            this.data.status = '拆除'
            break;
          case '4':
            this.data.status = '暂停'
            break;
          default:
            break;
        }
        this.getVisitorsFlowrate()
        this.getEquipmentList()
      })
    },
    getVisitorsFlowrate () {
      this.$http.getHummanTotal({ id: this.dataId }).then((res) => {
        if (res.code == 200) {
          this.visitorsFlowrate = res.result
        }
      })
    },
    getEquipmentList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        bindtype: this.data.facltypeid,
        bindobjid: this.dataId,
      }
      this.$http.getEquipmentList(params).then(res => {
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.tableEquipmentData = res.result.equiplist
        }
      })
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {
      this.data = {}
    }
  },
}
</script>

<style lang='scss' scoped>
.detail {
  .detail-top {
    padding: 8px 27px 0;
    .detail-top-title {
      display: flex;
      margin-bottom: 10px;
      .detail-top-title-icon {
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #0079fe;
        text-align: center;
        line-height: 50px;
        margin-right: 7px;
      }
      .detail-top-title-div {
        color: #333;
        font-size: 14px;
        p {
          font-size: 18px;
        }
        div {
          color: #6a6b75;
        }
      }
    }
    .detail-top-msg {
      display: flex;
      font-size: 13px;
      color: #333;
      margin-bottom: 4px;
      span {
        color: #0079fe;
        padding-right: 45px;
      }
    }
    .detail-top-img {
      width: 170px;
      height: 108px;
    }
  }
  .section {
    .section-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      border-bottom: 1px solid #ddd;
      margin-bottom: 10px;

      span:first-child {
        font-weight: 700;
        // font-size: 16px;
        padding-left: 10px;
        border-left: 2px solid #25bb96;
      }
    }
    .section-cont {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 15px;
      li {
        width: 50%;
        line-height: 34px;
        label {
          display: inline-block;
          min-width: 100px;
          text-align: right;
          margin-right: 10px;
        }
      }
      .one-row {
        width: 100%;
      }
      .img-row {
        display: flex;
        align-items: flex-start;
        img {
          width: 50%;
          margin-right: 10px;
        }
        span {
          margin-right: 10px;
        }
      }
    }
    .noData {
      p {
        color: #000;
      }
    }
  }
}
</style>