<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         width="600"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>设施类型：</p>
          <Input v-model="name"
                 placeholder="设施类型"
                 maxlength="30"
                 v-stringLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>设施大类：</p>
          <Select v-model="bigType"
                  placeholder="请选择设施大类">
            <Option :value="item.id"
                    v-for="item in bigTypeArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>监控图标：</p>
          <Select v-model="icon"
                  placeholder="监控图标"
                  @on-change="onChangeIcon">
            <img :src="getIconUrl"
                 slot="prefix"
                 v-if="icon"
                 style="width:30px;height:30px" />
            <Option :value="item.id"
                    v-for="item in iconArr"
                    :key="item.id">
              <img :src="item.name">
            </Option>
          </Select>
        </li>
        <li class="form-ul-li">
          <p class="li-title"></p>
          <div class="icon-div">
            <div class="icon-div-item">
              <div>
                <img :src="onLineIcon"
                     v-show="onLineIcon">
              </div>
              <p class="li-title">在线</p>
            </div>
            <div class="icon-div-item">
              <div>
                <img :src="offLineIcon"
                     v-show="offLineIcon">
              </div>
              <p class="li-title">离线</p>
            </div>
            <div class="icon-div-item">
              <div>
                <img :src="policeIcon"
                     v-show="policeIcon">
              </div>
              <p class="li-title">报警</p>
            </div>
          </div>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '设施类型'
    },
    dataId: String
  },
  data () {
    return {
      loading: false,
      name: '', // 设施类型
      bigType: '', // 设施大类
      bigTypeArr: [],
      icon: '', // 监控图标
      iconArr: [
        {
          id: 'maptoilet',
          name: require('../../../../../assets/common/facility/maptoilet_in.png')
        },
        {
          id: 'mapeel',
          name: require('../../../../../assets/common/facility/mapeel_in.png')
        },
        {
          id: 'maptransport',
          name: require('../../../../../assets/common/facility/maptransport_in.png')
        },
        {
          id: 'maptransfer',
          name: require('../../../../../assets/common/facility/maptransfer_in.png')
        },
        {
          id: 'mapkitchen',
          name: require('../../../../../assets/common/facility/mapkitchen_in.png')
        },
        {
          id: 'mapburn',
          name: require('../../../../../assets/common/facility/mapburn_in.png')
        },
        {
          id: 'mapdump',
          name: require('../../../../../assets/common/facility/mapdump_in.png')
        },
        {
          id: 'mapstop',
          name: require('../../../../../assets/common/facility/mapstop_in.png')
        },
        {
          id: 'mapshop',
          name: require('../../../../../assets/common/facility/mapshop_in.png')
        },
        {
          id: 'mapcollect',
          name: require('../../../../../assets/common/facility/mapcollect_in.png')
        },
        {
          id: 'manholecover',
          name: require('../../../../../assets/common/facility/manholecover_in.png')
        },
        {
          id: 'monitoringpoint',
          name: require('../../../../../assets/common/facility/monitoringpoint_in.png')
        },
        {
          id: 'mapholecover1',
          name: require('../../../../../assets/common/facility/mapholecover1_in.png')
        },
        {
          id: 'mapholecover2',
          name: require('../../../../../assets/common/facility/mapholecover2_in.png')
        },
        {
          id: 'mapholecover3',
          name: require('../../../../../assets/common/facility/mapholecover3_in.png')
        },
        {
          id: 'mapholecover4',
          name: require('../../../../../assets/common/facility/mapholecover4_in.png')
        },
        {
          id: 'mapholecover5',
          name: require('../../../../../assets/common/facility/mapholecover5_in.png')
        },
        {
          id: 'mapholecover6',
          name: require('../../../../../assets/common/facility/mapholecover6_in.png')
        },
        {
          id: 'mapholecover7',
          name: require('../../../../../assets/common/facility/mapholecover7_in.png')
        },
        {
          id: 'mapholecover8',
          name: require('../../../../../assets/common/facility/mapholecover8_in.png')
        },
        {
          id: 'mapholecover9',
          name: require('../../../../../assets/common/facility/mapholecover9_in.png')
        },
        {
          id: 'mapholecover10',
          name: require('../../../../../assets/common/facility/mapholecover10_in.png')
        },
        {
          id: 'mapholecover11',
          name: require('../../../../../assets/common/facility/mapholecover11_in.png')
        },
        {
          id: 'mapholecover12',
          name: require('../../../../../assets/common/facility/mapholecover12_in.png')
        },
        {
          id: 'mapholecover13',
          name: require('../../../../../assets/common/facility/mapholecover13_in.png')
        },
        {
          id: 'mapholecover14',
          name: require('../../../../../assets/common/facility/mapholecover14_in.png')
        },
        {
          id: 'mapholecover15',
          name: require('../../../../../assets/common/facility/mapholecover15_in.png')
        },
        {
          id: 'mapholecover16',
          name: require('../../../../../assets/common/facility/mapholecover16_in.png')
        },
        {
          id: 'mapholecover17',
          name: require('../../../../../assets/common/facility/mapholecover17_in.png')
        },
        {
          id: 'famoustrees',
          name: require('../../../../../assets/common/facility/famoustrees_in.png')
        },
        {
          id: 'landscaping',
          name: require('../../../../../assets/common/facility/landscaping_in.png')
        },
      ],
      remark: '', // 备注
      onLineIcon: '',
      offLineIcon: '',
      policeIcon: '',
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  computed: {
    getIconUrl () {
      return this.iconArr.find(item => item.id === this.icon).name
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateFacilityBigTypeAllList',
    ]),
    init () {
      this.updateFacilityBigTypeAllList()
      this.$http.getFacilityBigTypeAllList({
        type: 'facl'
      }).then(res => {
        if (res.code === 200) {
          this.bigTypeArr = res.result
        }
      })
    },
    // 获取详情
    getDetail () {
      this.$store.dispatch('getFacilitySmallTypeDetail', this.dataId).then(res => {
        for (const i in res) {
          this[i] = res[i]
        }
        if (res.icon) {
          this.onLineIcon = require('../../../../../assets/common/facility/' + res.icon + '_in.png')
          this.offLineIcon = require('../../../../../assets/common/facility/' + res.icon + '_out.png')
          this.policeIcon = require('../../../../../assets/common/facility/' + res.icon + '_alarm.png')
        }
      })
    },
    // 监控图标更改
    onChangeIcon (value) {
      if (value) {
        this.onLineIcon = require('../../../../../assets/common/facility/' + value + '_in.png')
        this.offLineIcon = require('../../../../../assets/common/facility/' + value + '_out.png')
        this.policeIcon = require('../../../../../assets/common/facility/' + value + '_alarm.png')
      }
    },
    onClickConfirm () {
      if (!this.name) return this.$Message.info('请输入设施类型');
      if (!this.bigType) return this.$Message.info('请选择设施大类');
      if (!this.icon) return this.$Message.info('请选择监控图标');
      let params = {
        name: this.name,
        bigType: this.bigType,
        icon: this.icon,
        remark: this.remark,
      }
      if (this.dataId) params.id = this.dataId
      this.loading = true
      this.$store.dispatch('createOrEditFacilitySmallType', params).then(res => {
        this.loading = false
        this.$store.dispatch('updateFacilityBigTypeAllList', true)
        this.$emit('onClickConfirm')
        this.$emit('onChange', false)
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetData () {
      this.name = ''
      this.bigType = ''
      this.icon = ''
      this.onLineIcon = ''
      this.offLineIcon = ''
      this.policeIcon = ''
      this.remark = ''
    }
  }
}
</script>

<style lang="scss" scoped>
// .section-title {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   height: 50px;
//   border-bottom: 1px solid #ddd;
//   margin-bottom: 10px;

//   span:first-child {
//     font-weight: 700;
//     font-size: 16px;
//     padding-left: 10px;
//     border-left: 2px solid #25bb96;
//   }
// }
.icon-div {
  display: flex;
  // text-align: center;
  .icon-div-item {
    div {
      width: 45px;
      height: 45px;
      background: #f0f3f6;
      margin: 0 auto;
      border: 1px solid #b3b0b0;
      border-radius: 3px;
      line-height: 45px;
      // text-align: center;
      img {
        width: 100%;
        height: 100%;
        // margin-right: 30px;
      }
    }
    p {
      text-align: center !important;
      margin: 5px 0 0 0;
    }
  }
}
</style>
