<template>
  <div class="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search"
           v-if="currentTabIndex===0">
        <div class="flex a-center j-between">
          <div class="flex a-center">
            <Select v-model="facilityBigType"
                    placeholder="设施大类"
                    clearable
                    @on-change="onChangeBigType"
                    class="m-r-10"
                    style="width:160px">
              <Option v-for="item in facilityBigTypeArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <Select v-model="facilitySmallType"
                    placeholder="设施小类"
                    clearable
                    :disabled="!facilityBigType"
                    class="m-r-10"
                    style="width:160px">
              <Option v-for="item in facilitySmallTypeArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <MyTreeSelect :value="name"
                          placeholder="设施名称"
                          clearable
                          class="m-r-10"
                          style="width:160px"
                          :data="nameArr"
                          search
                          checked
                          @onCheckChange="onChangeName">
            </MyTreeSelect>
            <Select v-model="status"
                    placeholder="状态"
                    clearable
                    class="m-r-10"
                    style="width:160px">
              <Option v-for="item in statusArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <MyTreeSelect :value="departName"
                          placeholder="维护单位"
                          clearable
                          class="m-r-10"
                          style="width:160px"
                          :data="departmentTreeArr"
                          @onSelectChange="onChangeDepart">
            </MyTreeSelect>
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/facilityArchives/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/facilityArchives/query'])"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex a-center j-between">
        <div class="flex">
          <template v-if="currentTabIndex===0">
            <div>
              <Button type="primary"
                      class="m-r-10"
                      v-if="checkPermi(['/admin/facilityArchives/add'])"
                      @click.stop="facilityVisible = true">
                <Icon custom="i-icon icon-tianjia1"
                      size="16"></Icon>
                添加
              </Button>
            </div>
            <Dropdown v-if="checkPermi(['/admin/facilityArchives/export'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-shangchuan"
                      size="16"></Icon>
                导出
              </Button>
              <DropdownMenu slot="list">
                <DropdownItem name="part">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('part')">
                    <Button>
                      导出部分
                    </Button>
                  </ExportTemplate>
                </DropdownItem>
                <DropdownItem name="now">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('now')">
                    <Button>
                      导出本页
                    </Button>
                  </ExportTemplate>
                </DropdownItem>
                <DropdownItem name="all">
                  <ExportTemplate :path="getExportPath"
                                  :data="getExportData('all')">
                    <Button>
                      导出全部
                    </Button>
                  </ExportTemplate>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <ExportTemplate :path="getExportTemplatePath"
                            :data="getExportTemplateData"
                            v-if="checkPermi(['/admin/facilityArchives/export'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-shangchuan"
                      size="16"></Icon>
                导出模板
              </Button>
            </ExportTemplate>
            <ReaderFile @uploadSuccess="onClickReader"
                        accept=".xls,.xlsx"
                        action='hss/tlfacilities/excelReader'
                        type="设施"
                        v-if="checkPermi(['/admin/facilityArchives/import'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-xiazai"
                      size="16"></Icon>
                导入
              </Button>
            </ReaderFile>
            <Dropdown @on-click="onClickBatch"
                      v-if="checkPermi(['/admin/facilityArchives/delete','/admin/facilityArchives/edit'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-xiugai1"
                      size="16"></Icon>
                批量操作
              </Button>
              <DropdownMenu slot="list">
                <DropdownItem name="recovery"
                              v-if="checkPermi(['/admin/facilityArchives/edit'])">批量启用</DropdownItem>
                <DropdownItem name="abandoned"
                              v-if="checkPermi(['/admin/facilityArchives/edit'])">批量废弃</DropdownItem>
                <DropdownItem name="repair"
                              v-if="checkPermi(['/admin/facilityArchives/edit'])">批量维修</DropdownItem>
                <DropdownItem name="dismantle"
                              v-if="checkPermi(['/admin/facilityArchives/edit'])">批量拆除</DropdownItem>
                <DropdownItem name="suspend"
                              v-if="checkPermi(['/admin/facilityArchives/edit'])">批量暂停</DropdownItem>
                <DropdownItem name="delete"
                              v-if="checkPermi(['/admin/facilityArchives/delete'])">批量删除</DropdownItem>
                <DropdownItem name="setAlarm"
                              v-if="checkPermi(['/admin/facilityArchives/edit'])">批量设置报警参数</DropdownItem>

              </DropdownMenu>
            </Dropdown>
          </template>
          <template v-if="currentTabIndex===1">
            <div>
              <Button type="primary"
                      class="m-r-10"
                      v-if="checkPermi(['/admin/facilityArchives/add'])"
                      @click.stop="facilityTypeVisible = true">
                <Icon custom="i-icon icon-tianjia1"
                      size="16"></Icon>
                添加
              </Button>
            </div>
            <Dropdown @on-click="onClickBatch"
                      v-if="checkPermi(['/admin/facilityArchives/delete'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-xiugai1"
                      size="16"></Icon>
                批量操作
              </Button>
              <DropdownMenu slot="list">
                <DropdownItem name="delete"
                              v-if="checkPermi(['/admin/facilityArchives/delete'])">
                  批量删除
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </template>
        </div>
      </div>
      <div class="flex">
        <div class="tree noScroll"
             :style="{height:tableHeight+50+'px'}"
             v-if="currentTabIndex===1||currentTabIndex===2">
          <div class="tree-title">设备类型</div>
          <ul class="tree-list">
            <li class="tree-item"
                :class="{active:currentTreeId===item.id}"
                v-for="item in facilityBigTypeArr"
                :key="item.id"
                @click.stop="onClickTreeItem(item.id)">{{item.name}}</li>
          </ul>
        </div>
        <div class="table">
          <template v-if="currentTabIndex===1||currentTabIndex===2">
            <Table ref="table"
                   :width="tableWidth"
                   :height="tableHeight"
                   :columns="getColumns"
                   :data="data"
                   border
                   stripe
                   :loading="tableLoading"
                   :row-class-name="rowClassName"
                   @on-selection-change="onChangeSelect">
              <template slot-scope="{ row }"
                        slot="operation">
                <template v-if="currentTabIndex===1">
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/facilityArchives/edit']"
                          @click.stop="onClickEditFacilityType(row)">修改</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/facilityArchives/delete']"
                          @click.stop="onClickDeleteFacilityType(row)">删除</Button>
                </template>
                <template v-if="currentTabIndex===2">
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/facilityArchives/edit']"
                          @click.stop="onClickEditAlarm(row)">修改</Button>
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/facilityArchives/edit']"
                          @click.stop="onClickStatusAlarm(row)">{{row.status==1?'禁用':'恢复'}}</Button>
                </template>
              </template>
            </Table>
          </template>
          <template v-else>
            <Table ref="table"
                   :width="tableWidths"
                   :height="tableHeight"
                   :columns="getColumns"
                   :data="data"
                   border
                   stripe
                   :loading="tableLoading"
                   :row-class-name="rowClassName"
                   @on-selection-change="onChangeSelect">
              <template slot-scope="{ row }"
                        slot="operation">
                <Button type="text"
                        class="tableOperaBtn"
                        v-hasPermi="['/admin/facilityArchives/edit']"
                        @click.stop="onClickEditFacility(row)">修改</Button>
                <Button type="text"
                        class="tableOperaBtn"
                        v-hasPermi="['/admin/facilityArchives/edit']"
                        @click.stop="onClickSetFacility(row)">设置</Button>
                <Button type="text"
                        class="tableOperaBtn"
                        v-hasPermi="['/admin/facilityArchives/delete']"
                        @click.stop="onClickDeleteFacility(row)">删除</Button>
                <Dropdown trigger="click"
                          transfer
                          @on-click="(name)=>{onClickStatusFacility(name,row)}">
                  <Button type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/facilityArchives/edit']">更多</Button>
                  <DropdownMenu slot="list">
                    <DropdownItem name="0"
                                  v-if="row.faclstatus!='正常'">恢复</DropdownItem>
                    <DropdownItem name="1"
                                  v-if="row.faclstatus!='废弃'">废弃</DropdownItem>
                    <DropdownItem name="2"
                                  v-if="row.faclstatus!='维修'">维修</DropdownItem>
                    <DropdownItem name="3"
                                  v-if="row.faclstatus!='拆除'">拆除</DropdownItem>
                    <DropdownItem name="4"
                                  v-if="row.faclstatus!='暂停'">暂停</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </template>
            </Table>
          </template>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>

    <!-- 添加设施 -->
    <FacilityModal v-model="facilityVisible"
                   :dataId.sync="facilityId"
                   @onClickConfirm="getList" @onChange='onChangClearRowStyle'></FacilityModal>

    <!-- 添加设施类型 -->
    <FacilityTypeModal v-model="facilityTypeVisible"
                       :dataId.sync="facilityTypeId"
                       @onClickConfirm="getList" @onChange='onChangClearRowStyle'></FacilityTypeModal>

    <!-- 设施详情 -->
    <FacilityDetailModal v-model="facilityDetailVisible"
                         :dataId.sync="facilityDetailId" @onChange='onChangClearRowStyle'></FacilityDetailModal>

    <!-- 设施状态 -->
    <FacilityStatusModal v-model="facilityStatusVisible"
                         :status="facilityStatus"
                         :item.sync="facilityStatusItem"
                         @onClickConfirm="getList" @onChange='onChangClearRowStyle'></FacilityStatusModal>

    <!-- 绑定设备 -->
    <BindEquipmentModal v-model="bindEquipmentVisible"
                        :item.sync="bindEquipmentObject"
                        @onClickConfirm="getList" @onChange='onChangClearRowStyle'></BindEquipmentModal>
    <!-- 查看地址 -->
    <PositionDetailModal v-model="positionDetailVisible"
                         :address="positionAddress"
                         :lng="positionLng"
                         :lat="positionLat" @onChange='onChangClearRowStyle'></PositionDetailModal>

    <!-- 点击设置，修改设施报警参数 -->
    <ParameterListModal :dataId.sync="parameterListId"
                        :faclBigType.sync="parameterListFaclBigType"
                        v-model="parameterListVisible"
                        thresholdType="facl" @onChange='onChangClearRowStyle'></ParameterListModal>

    <!-- 设施报警参数tab中的修改按钮 -->
    <ParameterModal v-model="parameterVisible"
                    :type="parameterType"
                    :dataId.sync="parameterId"
                    thresholdType="facl"
                    @onClickConfirm="getList" @onChange='onChangClearRowStyle'></ParameterModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MyTreeSelect from '@/components/common/MyTreeSelect'
import FacilityModal from "@/components/product/admin/modal/archives/FacilityModal"
import FacilityTypeModal from "@/components/product/admin/modal/archives/FacilityTypeModal"
import FacilityDetailModal from '@/components/product/admin/modal/archives/FacilityDetailModal'
import PositionDetailModal from '@/components/product/main/template/PositionDetailModal'
import FacilityStatusModal from '@/components/product/admin/modal/archives/FacilityStatusModal'
import BindEquipmentModal from '@/components/product/admin/modal/archives/BindEquipmentModal'
import ParameterListModal from '@/components/product/admin/modal/archives/ParameterListModal'
import ParameterModal from '@/components/product/admin/modal/archives/ParameterModal'
import ReaderFile from '@/components/common/ReaderFile'
import ExportTemplate from '@/components/common/ExportTemplate'
import { download, } from '@/utils/utils'
import { checkPermi } from '@/utils/permission'
export default {
  components: {
    MyTreeSelect,
    FacilityModal,
    FacilityTypeModal,
    FacilityDetailModal,
    PositionDetailModal,
    FacilityStatusModal,
    BindEquipmentModal,
    ParameterListModal,
    ParameterModal,
    ReaderFile,
    ExportTemplate
  },
  data () {
    return {
      currentTabIndex: 0,
      tabArr: [
        {
          name: '设施列表'
        },
        {
          name: '设施类型设置'
        },
        {
          name: '设施参数设置'
        },
      ],
      facilityBigType: '',
      facilityBigTypeArr: [],
      facilitySmallType: '',
      facilitySmallTypeArr: [],
      nameId: '',
      name: '',
      nameArr: [],
      status: '',
      statusArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '正常'
        },
        {
          id: 2,
          name: '废弃'
        },
        {
          id: 3,
          name: '维修'
        },
        {
          id: 4,
          name: '拆除'
        },
        {
          id: 5,
          name: '暂停'
        },
      ],
      departId: '',
      departName: '',
      currentTreeId: 0,
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableWidth: 0,
      tableWidths: 0,
      tableHeight: 0,
      tableLoading: false,
      columnsList: [
        {
          type: 'selection',
          width: 45,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '设施名称',
          key: 'faclname',
          width: 140,
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              class: {
                line1: true
              },
              on: {
                click: () => {
                  this.onClickFacilityDetail(row)
                }
              }
            }, row.faclname);
          }
        },
        {
          title: '设施编号',
          key: 'faclcode',
          width: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '设备绑定',
          key: 'binds',
          width: 60,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              class: {
                line1: true
              },
              on: {
                click: () => {
                  this.onClickBindEquipment(row)
                }
              }
            }, row.binds ? row.binds.length : '点击绑定');
          }
        },
        {
          title: '设施类型',
          key: 'facltypename',
          width: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'facldeptname',
          width: 180,
          tooltip: true,
          align: 'center',

        },
        {
          title: '负责人',
          key: 'principalname',
          width: 70,
          tooltip: true,
          align: 'center'
        },
        {
          title: '状态',
          key: 'faclstatus',
          width: 40,
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用日期',
          key: 'startdate',
          width: 80,
          tooltip: true,
          align: 'center'
        },
        {
          title: '点位半径(m)',
          key: 'radii',
          width: 80,
          tooltip: true,
          align: 'center'
        },
        {
          title: '地址',
          key: 'facladdrdetail',
          width: 160,
          tooltip: true,
          // placement:bottom,
          align: 'center',
          slot: "scope",

          render: (h, params) => {
            let row = params.row
            return h('Tooltip', {
              props: {
                content: row.facladdrdetail,
                'max-width': "200"
              },
            }, [
              h('span', {
                style: {
                  color: '#25bb96',
                  cursor: 'pointer'
                },
                class: {
                  line1: true
                },
                on: {
                  click: () => {
                    this.onClickFacilityAddress(row)
                  }
                }
              }, row.facladdrdetail),
            ]);
          }
        },
        {
          title: '维护单位',
          key: 'mtadeptname',
          width: 180,
          tooltip: true,
          align: 'center'
        },
        {
          title: '最近处理',
          key: 'recentdeal',
          width: 65,
          tooltip: true,
          align: 'center'
        },
        {
          title: '处理超时提醒',
          key: 'dealovertime',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        {
          title: '累计运行时长',
          key: 'totalworktime',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        {
          title: '商圈/社区/小区/饭店',
          key: 'businessarea',
          width: 130,
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '附属设施',
        //   key: 'subfacl',
        //   width: 90,
        //   tooltip: true,
        //   align: 'center',
        //   render: (h, params) => {
        //     let row = params.row
        //     return h('span', {
        //       style: {
        //         color: '#25bb96',
        //         cursor: 'pointer'
        //       },
        //       class: {
        //         line1: true
        //       },
        //       on: {
        //         click: () => {
        //           this.onClickBindFacility(row)
        //         }
        //       }
        //     }, row.subfacl ? row.subfacl : '点击绑定');
        //   }
        // },
        {
          title: '操作',
          slot: 'operation',
          width: 165,
          align: 'center'
        }
      ],
      columnsType: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '设施大类',
          key: 'facilitietypeid',
          width: 200,
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '设施小类',
          key: 'itemtypename',
          width: 200,
          tooltip: true,
          align: 'center'
        },
        {
          title: '备注',
          key: 'remark',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 180,
          align: 'center'
        }
      ],
      columnsSet: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '系统名称',
          key: 'sysname',
          width: 140,
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '报警名称',
          key: 'alarmname',
          width: 140,
          tooltip: true,
          align: 'center'
        },
        {
          title: '设施大类',
          key: 'faclitypename',
          width: 140,
          tooltip: true,
          align: 'center'
        },
        {
          title: '阈值',
          key: 'threshold',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'status',
          width: 90,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.status == 1) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已停用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 200,
          align: 'center'
        }
      ],
      data: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      // 新增 / 修改设施
      facilityVisible: false,
      facilityId: '',
      // 新增 / 修改设施类型
      facilityTypeVisible: false,
      facilityTypeId: '',
      // 设施详情
      facilityDetailVisible: false,
      facilityDetailId: '',
      // 设施状态
      facilityStatusVisible: false,
      facilityStatus: '',
      facilityStatusItem: null,
      // 绑定设备
      bindEquipmentVisible: false,
      bindEquipmentObject: null,
      // 点击设置，修改设施报警参数
      parameterListVisible: false,
      parameterListId: '',
      parameterListFaclBigType: '',
      // 查看地址
      positionDetailVisible: false,
      positionAddress: '',
      positionLng: 0,
      positionLat: 0,
      // 设施报警参数tab中的修改按钮
      parameterVisible: false,
      parameterId: '',
      parameterType: ''
    };
  },
  watch: {
    currentTabIndex (newVal) {
      this.resetSearchData()
      this.onClickSearch()
    },
    selectDataArr (newVal) {
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectIndexArr.push(data.id)
        })
      }
    },
    selectVisitedArr (newVal) {
      console.log(newVal);
      this.selectVisitedIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          let data = this.data.find((items, index) => {
            return item.id == items.id
          })
          this.selectVisitedIndexArr = [data.id]
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
    }),
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          columns = this.columnsList
          break;
        case 1:
          columns = this.columnsType
          break;
        case 2:
          columns = this.columnsSet
          break;
        default:
          break;
      }
      return columns
    },
    getExportPath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          path = this.$http.exportFacl
          break;
        default:
          break;
      }
      return path
    },
    getExportTemplatePath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          path = this.$http.exportFaclTem
          break;
        default:
          break;
      }
      return path
    },
    getExportTemplateData () {
      let data = {}
      switch (this.currentTabIndex) {
        case 0:
          data = { type: '设施' }
          break;
        default:
          break;
      }
      return data
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateFacilityBigTypeAllList',
      'updateDepartmentTreeList',
    ]),
    init () {
      this.tableWidths = window.innerWidth - 240
      this.tableWidth = window.innerWidth - 490
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableWidths = window.innerWidth - 240
        this.tableWidth = window.innerWidth - 490
        this.tableHeight = window.innerHeight - 350
      }
      this.updateFacilityBigTypeAllList()
      this.updateDepartmentTreeList()
      this.onChangeBigType()
      this.$http.getFacilityBigTypeAllList({
        type: 'facl'
      }).then(res => {
        if (res.code === 200) {
          let arr = [{
            id: 0,
            name: '全部'
          }]
          this.facilityBigTypeArr = arr.concat(res.result)
        }
      })
      let barch = document.querySelector('#faclBatch')
      if (barch && barch.children.length) {
        this.faclBarchVisible = true
      } else {
        this.faclBarchVisible = false
      }
      this.getList()
    },
    onChangeBigType (value) {
      if (value) {
        let arr = [{
          id: 0,
          name: '全部'
        }]
        this.$http.getFacilitySmallTypeAllList({ facilitietypeid: value }).then(res => {
          if (res.code === 200) {
            this.facilitySmallTypeArr = arr.concat(res.result)
          }
        })
        this.$http.getBindObjectTreeList({ treetype: value }).then(res => {
          if (res.code === 200) {
            this.nameArr = res.result
          }
        })
      } else {
        this.facilitySmallTypeArr = []
        this.$http.getBindObjectTreeList({ treetype: 'facl' }).then(res => {
          if (res.code === 200) {
            this.nameArr = res.result
          }
        })
      }
      this.facilitySmallType = ''
      // this.nameId = ''
      this.name = ''
    },
    onChangeName (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type !== 'depart') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      // this.nameId = idArr.join()
      this.name = valueArr.join()
    },
    onChangeDepart (data) {
      this.departId = data.id
      this.departName = data.name
    },
    rowClassName (row, index) {
      if (this.selectVisitedIndexArr.includes(row.id)) {
        return 'table-visited-bgcolor'
      }
      if (this.selectIndexArr.includes(row.id)) {
        return 'table-bgcolor'
      }
    },
    onClickTreeItem (id) {
      this.currentTreeId = id
      this.onClickSearch()
    },
    onClickBatch (name) {
      if (this.selectArr.length == 0) return this.$Message.info('请选择数据')
      switch (name) {
        case 'recovery':
          this.onStatusFacility(this.selectArr, 0)
          break;
        case 'abandoned':
          this.onStatusFacility(this.selectArr, 1)
          break;
        case 'repair':
          this.onStatusFacility(this.selectArr, 2)
          break;
        case 'dismantle':
          this.onStatusFacility(this.selectArr, 3)
          break;
        case 'suspend':
          this.onStatusFacility(this.selectArr, 4)
          break;
        case 'delete':
          switch (this.currentTabIndex) {
            case 0:
              this.onDeleteFacility(this.selectArr)
              break;
            case 1:
              this.onDeleteFacilityType(this.selectArr)
              break;
            default:
              break;
          }
          break;
        case 'setAlarm':
          let firstTypeId = this.selectDataArr[0].facltypeid
          let boo = true
          this.selectDataArr.forEach(item => {
            if (item.facltypeid != firstTypeId) {
              boo = false
            }
          })
          if (boo) {
            this.parameterListId = this.selectArr.join()
            this.parameterListFaclBigType = firstTypeId
            this.parameterListVisible = true
          } else {
            this.$Message.info('所选数据设施类型不同！')
          }
          break;
        default:
          break;
      }
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
      this.selectDataArr = selection
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getList () {
      this.selectIndexArr = []
      switch (this.currentTabIndex) {
        case 0:
          this.getFacilityList()
          break;
        case 1:
          this.getFacilitySmallTypeList()
          break;
        case 2:
          this.getAlarmList()
          break;
        default:
          break;
      }
    },
    getFacilityList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        type: '设施',
        facltypeid: '',
        facltypeitemid: '',
        faclname: this.name,
        faclstatus: '',
        mtadeptId: this.departId
      }
      if (this.facilityBigType) params.facltypeid = this.facilityBigType
      if (this.facilitySmallType) params.facltypeitemid = this.facilitySmallType
      if (this.status) params.faclstatus = this.status - 1
      this.tableLoading = true
      this.$http.getFacilityList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.factypelist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    getFacilitySmallTypeList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        facilitietypeid: 'facl',
      }
      if (this.currentTreeId) params.facilitietypeid = this.currentTreeId
      this.tableLoading = true
      this.$http.getFacilitySmallTypeList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.factypeitemlist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    getAlarmList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        thresholdtype: 'facl',
        faclitypeid: ''
      }
      if (this.currentTreeId) params.faclitypeid = this.currentTreeId
      this.tableLoading = true
      this.$http.getAlarmList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.thresholdlist
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 重置搜索条件
    resetSearchData () {
      this.selectArr = []
      this.facilityBigType = ''
      this.onChangeBigType()
      this.status = ''
      this.departId = ''
      this.departName = ''
      this.currentTreeId = 0
    },
    onChangClearRowStyle () {
      this.selectVisitedArr = []
      
    },
    // 点击设施详情
    onClickFacilityDetail (row) {
      this.facilityDetailId = row.id
      this.facilityDetailVisible = true
      this.selectVisitedArr.push(row)
    },
    // 点击绑定设备
    onClickBindEquipment (row) {
      row.type = row.facltypeid
      row.item = row.facltypeitemid
      this.bindEquipmentObject = row
      this.bindEquipmentVisible = true
      this.selectVisitedArr.push(row)
    },
    // 点击查看地址
    onClickFacilityAddress (row) {
      this.positionAddress = row.facladdrdetail
      let lnglat = row.facladdr.split(',').filter(item => item)
      if (lnglat.length < 2) return this.$Message.info('地址信息错误')
      this.positionLng = parseFloat(lnglat[0])
      this.positionLat = parseFloat(lnglat[1])
      this.positionDetailVisible = true
      this.selectVisitedArr.push(row)
    },
    // 点击修改设施
    onClickEditFacility (row) {
      this.facilityId = row.id
      this.facilityVisible = true
      this.selectVisitedArr.push(row)
    },
    // 点击设置参数
    onClickSetFacility (row) {
      this.parameterListId = row.id
      this.parameterListVisible = true
      this.selectVisitedArr.push(row)
    },
    // 点击删除设施
    onClickDeleteFacility (row) {
      this.onDeleteFacility([row.id])
      this.selectVisitedArr.push(row)
    },
    // 删除记录
    onDeleteFacility (arr) {
      this.$Modal.confirm({
        title: '删除',
        content: '是否解绑设备并删除?',
        onOk: () => {
          let params = {
            ids: arr.join()
          }
          this.$http.deleteBatchFacility(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangClearRowStyle()
              this.selectArr = []
              this.getList()
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 修改设施状态
    onClickStatusFacility (name, row) {
      this.facilityStatus = name
      this.facilityStatusItem = row
      this.facilityStatusVisible = true
      this.selectVisitedArr.push(row)
    },
    onStatusFacility (arr, status) {
      let statusStr
      switch (status) {
        case 0:
          statusStr = '启用'
          break;
        case 1:
          statusStr = '废弃'
          break;
        case 2:
          statusStr = '维修'
          break;
        case 3:
          statusStr = '拆除'
          break;
        case 4:
          statusStr = '暂停'
          break;
        default:
          break;
      }
      this.$Modal.confirm({
        title: statusStr,
        content: '确认' + statusStr + '?',
        onOk: () => {
          let params = {
            ids: arr.join(),
            status: status,
          }
          this.$store.dispatch('createOrEditFacility', params).then(res => {
            this.getList()
            this.$store.dispatch('updateFacilityBigTypeAllList', true)
          })
          this.onChangClearRowStyle()
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 点击修改设施小类
    onClickEditFacilityType (row) {
      this.facilityTypeId = row.id
      this.facilityTypeVisible = true
      this.selectVisitedArr.push(row)
    },
    // 删除设施小类
    onClickDeleteFacilityType (row) {
      this.selectVisitedArr.push(row)
      this.onDeleteFacilityType([row.id])
    },
    onDeleteFacilityType (arr) {
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            ids: arr.join()
          }
          this.$http.deleteBatchFacilitySmallType(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangClearRowStyle()
              this.selectArr = []
              this.getList()
              this.$store.dispatch('updateFacilityBigTypeAllList', true)
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 修改报警参数
    onClickEditAlarm (row) {
      this.parameterVisible = true
      this.parameterType = row.sysname
      this.parameterId = row.id
      this.selectVisitedArr.push(row)
    },
    // 修改报警参数状态
    onClickStatusAlarm (row) {
      this.selectVisitedArr.push(row)
      if (row.status == 1) {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              status: 0,
              thresholdtype: 'facl',
              name: row.alarmname,
              sysname: row.sysname,
              faclitypeid: row.faclitypeid,
            }
            this.$store.dispatch('editTableAlarmStatus', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
        })
      } else {
        this.$Modal.confirm({
          title: '恢复',
          content: '确认恢复?',
          onOk: () => {
            let params = {
              status: 1,
              thresholdtype: 'facl',
              name: row.alarmname,
              sysname: row.sysname,
              faclitypeid: row.faclitypeid,
            }
            this.$store.dispatch('editTableAlarmStatus', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
        })
      }
    },
    // 导出文件数据
    getExportData (value) {
      let data = {}
      switch (this.currentTabIndex) {
        case 0:
          data = {
            page: this.pageNum,
            size: this.pageSize,
            type: '设施',
            facltypeid: '',
            facltypeitemid: '',
            faclname: this.name,
            faclstatus: '',
            mtadeptId: this.departId
          }
          if (this.facilityBigType) data.facltypeid = this.facilityBigType
          if (this.facilitySmallType) data.facltypeitemid = this.facilitySmallType
          if (this.status) data.faclstatus = this.status - 1
          if (value == 'now') {
            data.idlist = this.data.map(item => {
              return item.id
            })
          } else if (value == 'part') {
            data.idlist = this.selectArr
          } else {
            // data.idlist = []
          }
          break;
        default:
          break;
      }
      return data
    },
    // 导入回调
    onClickReader (arr) {
      if (arr.length > 0) {
        this.$Message.info('导入成功')
        this.getList()
      }
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;

  .topbar {
    background-color: #fff;

    .topbar-search {
      padding: 16px;
    }
  }

  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;

    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
      display: flex;
      flex-direction: column;

      .tree-title {
        background: #f5f5f5;
        font-size: 14px;
        color: #25bb96;
        padding: 15px 0 15px 12px;
        font-weight: bold;
      }

      .tree-list {
        flex: 1;
        overflow: auto;
        .tree-item {
          padding: 0 8px;
          line-height: 36px;
          cursor: pointer;

          &:hover {
            background: #f0f3f7;
          }

          &.active {
            background: #f0f3f7;
          }
        }
      }
    }

    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>
